import React from 'react';
import {useTranslation} from 'react-i18next';
import {Logout as LogoutIcon} from '@mui/icons-material';
import {ListItemText} from '@mui/material';
import {LoggedInState, useUserContext} from '@/Contexts/User/UserContext';
import {LeftMenuItem, PrimaryColorListItemIcon} from '@/Components/MenuComponents';


const LogoutMenuItem = (): React.JSX.Element => {
    const {t} = useTranslation('common');
    const {loggedInState, attemptLogout} = useUserContext();

    const [isLoggedIn, setIsLoggedIn] = React.useState(false);

    React.useEffect(() => {
        if (loggedInState === LoggedInState.LoggedIn) {
            setIsLoggedIn(true);
        } else if (loggedInState === LoggedInState.LoggedOut) {
            setIsLoggedIn(false);
        }
    }, [loggedInState]);

    const handleAccountLogoutClick = (): void => {
        attemptLogout().catch((reason) => console.log(reason));
    };

    return isLoggedIn
        ? <LeftMenuItem onClick={handleAccountLogoutClick}>
            <PrimaryColorListItemIcon>
                <LogoutIcon/>
            </PrimaryColorListItemIcon>
            <ListItemText>
                {t('account.logout')}
            </ListItemText>
        </LeftMenuItem>
        : <></>;
};

export default LogoutMenuItem;
