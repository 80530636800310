import React from 'react';
import {useTranslation} from 'react-i18next';
import styled from 'styled-components';
import {WalleeLanguageName, WalleePaymentMethod} from '@/Areas/Connector/Data/PaymentMethod';
import {fetchWithRetry, withCredentialsAndContentType} from '@/Helpers/fetchHelpers';
import {globalConfig} from '@/Helpers/globalConfig';
import {convertLangToWalleeLang} from '@/Areas/Connector/Helpers/WalleeHelpers';
import CenteredCircularProgress from '@/Components/CenteredCircularProgress';
import {PaymentError} from '@/Areas/Connector/Data/ConnectorTypes';

type PaymentMethodSelectorProps = {
    paymentMethods: [WalleePaymentMethod] | undefined;
    setPaymentMethods: React.Dispatch<React.SetStateAction<[WalleePaymentMethod] | undefined>>;
    setError: React.Dispatch<React.SetStateAction<PaymentError | undefined>>;
    selectedPaymentMethod: number | undefined;
    setSelectedPaymentMethod: React.Dispatch<React.SetStateAction<number | undefined>>;
};

const PaymentMethodSelector = (props: PaymentMethodSelectorProps): React.JSX.Element => {
    const [loading, setLoading] = React.useState(true);
    const [walleeLang, setWalleeLang] = React.useState<WalleeLanguageName>('en-US');
    const {paymentMethods, setPaymentMethods, setError, selectedPaymentMethod, setSelectedPaymentMethod} = props;
    const {i18n, t} = useTranslation(['common', 'connector']);

    const getPaymentMethods = (): Promise<[WalleePaymentMethod]> => {
        return fetchWithRetry(
            (globalConfig.customerApiUrl as string) + '/transactions/current/payment_methods',
            withCredentialsAndContentType({
                method: 'GET',
            }),
        )
            .then(result => {
                if (!Array.isArray(result) || result.length === 0) {
                    console.error('Unexpected payment method result', result);
                    return Promise.reject('Not an array or empty array');
                } else {
                    return result as [WalleePaymentMethod];
                }
            })
            .catch(reason => {
                console.error('Could not fetch payment methods', reason);
                setError(PaymentError.NO_PAYMENT_METHODS);
                return Promise.reject(reason);
            });
    };

    React.useEffect(() => {
        getPaymentMethods()
            .then(fetchedMethods => {
                setPaymentMethods(fetchedMethods);
                if (fetchedMethods.length === 1) {
                    setSelectedPaymentMethod(fetchedMethods[0].id);
                }
            })
            .catch(() => undefined)
            .finally(() => setLoading(false));
    }, []);

    React.useEffect(() => {
        setWalleeLang(convertLangToWalleeLang(i18n.language));
    }, [i18n.language]);

    const handleSelectedMethodChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        setSelectedPaymentMethod(Number(e.currentTarget.value));
    };

    if (loading || !paymentMethods) {
        return <CenteredCircularProgress/>;
    }

    if (paymentMethods.length === 1) {
        return <React.Fragment/>;
    }

    return (
        <PaymentMethodFieldset>
            <PaymentMethodLegend>{t('connector:choose_payment_method')}</PaymentMethodLegend>
            {paymentMethods.map(paymentMethod => {
                const checked = selectedPaymentMethod === paymentMethod.id;

                return (
                    <PaymentMethodLabel key={paymentMethod.id}>
                        <PaymentIcon src={paymentMethod.resolvedImageUrl} alt=""/>
                        <input
                            type="radio"
                            name="paymentMethod"
                            onChange={handleSelectedMethodChange}
                            value={paymentMethod.id}
                            checked={checked}
                        />
                        <p>
                            <span>
                                {paymentMethod.resolvedTitle[walleeLang]} {checked && '(' + t('common:selected') + ')'}
                            </span>
                            <br/>
                            <span>{paymentMethod.resolvedDescription[walleeLang]}</span>
                        </p>
                    </PaymentMethodLabel>
                );
            })}
        </PaymentMethodFieldset>
    );
};

const PaymentMethodFieldset = styled.fieldset`
    border: 0;
    padding: 0;
    margin-bottom: 1rem;
`;

const PaymentMethodLegend = styled.legend`
    font-weight: bold;
    font-size: large;
`;

const PaymentMethodLabel = styled.label`
    display: flex;
    cursor: pointer;
    border: 2px solid ${props => props.theme.palette.secondary.dark as string};
    border-radius: 5px;
    margin: 0.5rem;
    padding: 0.5rem;

    &:has(input:checked) {
        border-color: ${props => props.theme.palette.primary.main as string};
    }

    & > input {
        position: fixed;
        opacity: 0;
    }
`;

const PaymentIcon = styled.img`
    width: 5rem;
    height: auto;
    margin-right: 2rem;
`;

export default PaymentMethodSelector;
