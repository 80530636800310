import React from 'react';
import {callWithJwt} from '@/Helpers/jwtHelpers';
import type {BankAccount} from '@/Areas/Payment/Data/BankAccount';

export const fetchBankAccounts = (
    setBankAccounts: React.Dispatch<React.SetStateAction<BankAccount[] | undefined>>,
    setError: React.Dispatch<React.SetStateAction<boolean>>,
): Promise<void> => {
    return callWithJwt<{items: BankAccount[]}>('/accounts', 'GET')
        .then((response) => {
            if (response.data) {
                setBankAccounts(response.data.items);
                return Promise.resolve();
            } else {
                return Promise.reject(response);
            }
        })
        .catch((reason) => {
            console.error('Failed to fetch bank accounts', reason);
            setError(true);
        });
};

export const getBankAccountLabel = (bankAccount: BankAccount): string => {
    return String(bankAccount.name === '' ? bankAccount.identifier : bankAccount.name);
};

export const getBankAccountLabelFromId = (id: number, bankAccounts: BankAccount[]): string => {
    const foundAccount = bankAccounts!.find((account) => account.id === id);
    if (foundAccount) {
        return getBankAccountLabel(foundAccount);
    } else {
        console.error('Could not find bank account', id);
        return String(id);
    }
};
