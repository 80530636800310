import React from 'react';
import {useParams} from 'react-router-dom';
import {TokenAddWithoutHoc} from '@/Areas/Payment/Views/TokenAdd';
import withLoginRequirement from '@/Components/withLoginRequirement';

const TokenAddWithAuth = (): React.JSX.Element => {
    const {auth} = useParams();

    return <TokenAddWithoutHoc auth={auth}/>;
};

export default withLoginRequirement(TokenAddWithAuth);
