import React from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import CenteredCircularProgress from '@/Components/CenteredCircularProgress';
import NotFound from '@/Areas/NotFound/Views/NotFound';

const TransactionRedirection = (): React.JSX.Element => {
    const {uid, urlToken} = useParams();
    const navigate = useNavigate();
    const [waiting, setWaiting] = React.useState(true);

    React.useEffect(() => {
        if (uid!.match(/^A[A-Z][a-zA-Z-z\d]+$/)) {
            navigate('/receipt/' + (uid as string) + '?token=' + (urlToken as string));
        } else {
            setWaiting(false);
        }
    }, []);

    if (waiting) {
        return <CenteredCircularProgress/>;
    }

    return <NotFound/>;
};

export default TransactionRedirection;
