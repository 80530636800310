import {BooleanValidator, ObjectValidator, StringValidator} from 'formifly';
import {TFunction} from 'i18next';
import punycode from 'punycode/punycode';
import {PunycodeEmailValidator} from '@common/butterfly-shared-react-library';
import {globalConfig} from '@/Helpers/globalConfig';
import {bufferDecode, bufferEncode} from '@/Areas/Customer/Helpers/CustomerHelpers';

export const getLoginShape = (t: TFunction<any>): ObjectValidator<any> => {
    return new ObjectValidator({
        email: new PunycodeEmailValidator().required(t('error.email_required')),
        password: new StringValidator().required(t('error.password_required')),
        stay_logged_in: new BooleanValidator(),
    });
};

export const getWebAuthNLoginShape = (): ObjectValidator<any> => {
    return new ObjectValidator({
        stay_logged_in_webauthn: new BooleanValidator(),
    });
};

export const makeLoginRequest = async (email: string, password: string, stayLoggedIn: boolean): Promise<any> => {
    const emailRegexp = /.+@.+/;
    if (emailRegexp.test(email)) {
        email = punycode.toASCII(email);
    }
    const response = await fetch(globalConfig.customerApiUrl as string + '/auth', {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({
            email: email,
            password: password,
            stay_logged_in: stayLoggedIn,
        }),
    });
    if (response.ok) {
        return response.json();
    } else {
        return Promise.reject(await response.json());
    }
};

export const makeWebAuthNLoginRequest = async (stayLoggedIn: boolean): Promise<any> => {
    const authCredential = await createAuthCredential()
        .then((response) => response)
        .catch((reason) => {
            return Promise.reject(reason);
        });

    return await fetch(globalConfig.customerApiUrl as string + '/auth/webauthn', {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({
            credential: authCredential,
            stay_logged_in: stayLoggedIn,
        }),
    }).then(response => {
        return response.json();
    }).catch((error: any) => {
        return Promise.reject(error);
    });
};

type authOptionsType = {
    challenge?: string | Uint8Array,
    allowCredentials?: any[],
}

const createAuthCredential = async (): Promise<any> => {
    const authOptions: authOptionsType = await fetch(globalConfig.customerApiUrl as string + '/auth/webauthn/options', {
        method: 'POST',
        mode: 'cors',
        credentials: 'include',
    }).then(response => response.json())
        .catch((reason) => {
            console.error('Error fetching auth options: ', reason);
            return Promise.reject(reason);
        });

    if (authOptions && authOptions.challenge && Array.isArray(authOptions.allowCredentials)) {
        authOptions.challenge = bufferDecode(authOptions.challenge);

        authOptions.allowCredentials.forEach((listItem: any) => {
            listItem.id = bufferDecode(listItem.id);
        });

        return navigator.credentials.get({
            publicKey: authOptions as any,
        }).then((credentialContainer: any | null) => {
            if (credentialContainer) {
                return {
                    id: credentialContainer.id,
                    authenticatorAttachment: credentialContainer.authenticatorAttachment,
                    rawId: bufferEncode(credentialContainer.rawId),
                    type: credentialContainer.type,
                    response: {
                        authenticatorData: bufferEncode(credentialContainer.response.authenticatorData),
                        clientDataJSON: bufferEncode(credentialContainer.response.clientDataJSON),
                        signature: bufferEncode(credentialContainer.response.signature),
                        userHandle: bufferEncode(credentialContainer.response.userHandle),
                    },
                };
            } else {
                return Promise.reject('could not create credential');
            }
        }).catch((reason) => {
            console.error('Error getting credential: ', reason);
            return Promise.reject('error getting credential');
        });
    } else {
        console.error('Error getting Authentication Options');
        return Promise.reject('could not get auth options');
    }
};

//export const getPasswordChangeShape = (t: TFunction) => {
//   return new ObjectValidator({
//        password: new StringValidator().required(),
//        password_repeat: new PasswordRepeatValidator('password').required(),
//    })
//}

