import {Alert, Button, Card, CardContent, CardHeader, Grid} from '@mui/material';
import {FormiflyForm, SubmitFunction, withFormifly} from 'formifly';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import styled from 'styled-components';
import {FormiflyMuiField} from '@common/butterfly-shared-react-library';
import {WithFormiflyProps} from 'formifly/dist/src/js/components/meta/withFormifly';
import {globalConfig} from '@/Helpers/globalConfig';
import {getTransactionSearchShape} from '@/Areas/Transaction/Helpers/transactionHelpers';

const TransactionSearch = (): React.JSX.Element => {
    const navigate = useNavigate();
    const {t} = useTranslation(['common', 'search', 'formifly']);
    const [notEnoughFields, setNotEnoughFields] = React.useState(false);
    const [error, setError] = React.useState<undefined | string>(undefined);

    const searchValidationShape = getTransactionSearchShape(t);

    const handleSubmit: SubmitFunction<typeof searchValidationShape> = (values) => {
        setError(undefined);
        const postValues: {[key: string]: any} = {...values};

        if (postValues.token_type === 'APPLE_OR_GOOGLE') {
            if (!postValues.connector_uid) {
                setNotEnoughFields(true);
                return Promise.reject();
            }
            postValues.token_type = 'WALLEE_CREDITCARD';
        } else if (Object.keys(postValues).length < 4) {
            setNotEnoughFields(true);
            return Promise.reject();
        }

        setNotEnoughFields(false);
        return fetch((globalConfig.customerApiUrl as string)
            + '/transactions/by-identifiers?'
            + (new URLSearchParams(postValues as URLSearchParams) as unknown as string), {})
            .then(async (result) => {
                const json = await result.json();
                if (result.ok) {
                    navigate('/receipt/' + (json.uid as string) + '?token=' + (json.receipt_token_full as string), {state: {transaction: json}});
                } else {
                    return Promise.reject(json);
                }
            })
            .catch((reason) => {
                console.log(reason);
                if (reason?.error?.code) {
                    setError(reason.error.code);
                } else {
                    setError('unexpected');
                }
            });
    };


    return <>
        <SearchSection>
            <CardHeader title={t('search:heading')} component={CardHeading}/>
            <CardContent>
                <p>{t('search:body')}</p>
                {Boolean(error) && <AlertWithMargin severity="error">
                    {error === 'not_found' && t('search:error.not_found')
                        || error === 'additional_input_data_required' && t('search:error.additional_input_data_required')
                        || t('search:error.unexpected')}
                </AlertWithMargin>}
                <FormiflyForm shape={searchValidationShape} onSubmit={handleSubmit} t={t}>
                    {notEnoughFields && <div aria-live="polite" aria-relevant="additions">
                        <NotEnoughFieldsWarning>{t('search:not_enough_fields')}</NotEnoughFieldsWarning>
                    </div>}
                    <SearchForm/>
                </FormiflyForm>
            </CardContent>
        </SearchSection>
        <SearchSection>
            <CardHeader title={t('search:did_you_know')}/>
            <CardContent>
                <p>{t('search:did_you_know_body')}</p>
            </CardContent>
        </SearchSection>
    </>;
};

const SearchForm = withFormifly((props: React.PropsWithChildren<WithFormiflyProps>): React.JSX.Element => {
    const {values} = props;
    const {t} = useTranslation(['search', 'formifly']);

    return <Grid container spacing={1}>
        <HomeFormElementGrid item xs={12} lg={4}>
            <FormiflyMuiField name="value_gross" label={t('search:value_gross')}/>
        </HomeFormElementGrid>
        <HomeFormElementGrid item xs={12} lg={4}>
            <FormiflyMuiField name="authorized_at" label={t('search:authorized_at')}/>
        </HomeFormElementGrid>
        <HomeFormElementGrid item xs={12} lg={4}>
            <FormiflyMuiField name="connector_uid" label={t('search:connector_uid')}/>
        </HomeFormElementGrid>
        <HomeFormElementGrid item xs={12} lg={4}>
            <FormiflyMuiField name="token_type"
                              label={t('search:token_type.label')}
                              options={[
                                  {value: 'WALLEE_CREDITCARD', label: t('search:token_type.WALLEE_CREDITCARD')},
                                  {value: 'GIROCARD', label: t('search:token_type.GIROCARD')},
                                  {value: 'APPLE_OR_GOOGLE', label: t('search:token_type.APPLE_OR_GOOGLE')},
                              ]}/>
        </HomeFormElementGrid>
        {values.token_type === 'WALLEE_CREDITCARD'
            && <>
                <HomeFormElementGrid item xs={12} lg={8}>
                    <FormiflyMuiField name="token_creditcard_number" label={t('search:token_creditcard_number')}/>
                </HomeFormElementGrid>
                {/*<Grid item xs={12} lg={6}>*/}
                {/*    <FormiflyMuiField name="token_expiration" label={t('find:token_expiration')}/>*/}
                {/*</Grid>*/}
            </>
            || values.token_type === 'GIROCARD' && <HomeFormElementGrid item xs={12} lg={8}>
                <FormiflyMuiField name="debitor_account_identifier" label={t('search:debitor_account_identifier')}/>
            </HomeFormElementGrid>}
        <HomeFormElementGrid item xs={12}>
            <Button color="primary" type="submit" variant="contained" fullWidth>{t('search:search')}</Button>
        </HomeFormElementGrid>
    </Grid>;
});

const SearchSection = styled(Card)`
    margin-bottom: 20px;
`;

const HomeFormElementGrid = styled(Grid)`
    margin-bottom: 5px;
`;

const AlertWithMargin = styled(Alert)`
    margin: 1rem;
`;

const NotEnoughFieldsWarning = styled.p`
    color: ${props => props.theme.palette.error.main};
`;

const CardHeading = styled.h2`
    margin: 0;
`;

export default TransactionSearch;
