import {useRoutes} from 'react-router-dom';
import React from 'react';
import NotFound from '@/Areas/NotFound/Views/NotFound';
import AccountOverview from '@/Areas/Customer/AccountOverview';
import Home from '@/Areas/Home/Views/Home';
import Connector from '@/Areas/Connector/Views/Connector';
import {globalConfig} from '@/Helpers/globalConfig';
import TransactionSearch from '@/Areas/Transaction/Views/TransactionSearch';
import TransactionReceipt from '@/Areas/Transaction/Views/TransactionReceipt';
import TransactionStarted from '@/Areas/Connector/Views/TransactionStarted';
import Auth from '@/Areas/Auth/Views/Auth';
import BankAccountOverview from '@/Areas/Payment/Views/BankAccountOverview';
import TransactionRedirection from '@/Areas/Transaction/Views/TransactionRedirection';
import BankAccountCreation from '@/Areas/Payment/Views/BankAccountCreation';
import BankAccountEdit from '@/Areas/Payment/Views/BankAccountEdit';
import AccountSecurityOverview from '@/Areas/Customer/AccountSecurityOverview';
import HomeSwitch from '@/Areas/Home/Views/HomeSwitch';
import TokenOverview from '@/Areas/Payment/Views/TokenOverview';
import TokenAdd from '@/Areas/Payment/Views/TokenAdd';
import TokenEdit from '@/Areas/Payment/Views/TokenEdit';
import TokenAddWithAuth from '@/Areas/Payment/Views/TokenAddWithAuth';

const Routes = (): React.ReactElement | null => {
    const routes = [
        {
            path: '/go/:connectorUid/trans/:transactionRequestUid',
            element: <TransactionStarted/>,
        },
        {
            path: '/go/:connectorUid',
            element: <Connector/>,
        },
        {
            path: '/account',
            element: <AccountOverview/>,
        },
        {
            path: '/search',
            element: <TransactionSearch/>,
        },
        {
            path: '/receipt/:uid',
            element: <TransactionReceipt/>,
        },
        {
            path: '/:uid/:urlToken',
            element: <TransactionRedirection/>,
        },
        {
            path: '/',
            element: <HomeSwitch/>,
        },
        {
            path: '*',
            element: <NotFound/>,
        },
    ];
    if (globalConfig.enableLogin) {
        routes.push({
                path: '/login',
                element: <Auth/>,
            },
            {
                path: '/logout',
                element: <Home logout={true}/>,
            },
            {
                path: '/neu/:auth',
                element: <TokenAddWithAuth/>,
            },
            {
                path: '/account/payment/token',
                element: <TokenOverview/>,
            },
            {
                path: '/account/payment/token/add',
                element: <TokenAdd/>,
            },
            {
                path: '/account/payment/token/:id/edit',
                element: <TokenEdit/>,
            },
            {
                path: '/account/security',
                element: <AccountSecurityOverview/>,
            },
            {
                path: '/account/payment/bank',
                element: <BankAccountOverview/>,
            },
            {
                path: '/account/payment/bank/add',
                element: <BankAccountCreation/>,
            },
            {
                path: '/account/payment/bank/:id/edit',
                element: <BankAccountEdit/>,
            });
    }

    return useRoutes(routes);
};

export default Routes;
