import {ObjectValidator, StringValidator} from 'formifly';
import {BICValidator, IbanValidator} from '@common/butterfly-shared-react-library';

export type BankAccount = {
    name?: string,
    id: number,
    identifier: string,
    bic: string,
    internal_comment: string,
};

export const getBankAccountShape = (needsBIC: boolean): ObjectValidator<any> => {
    const validatorShape = new ObjectValidator({
        identifier: new IbanValidator().required(),
        name: new StringValidator().maxLength(255),
        bic: new BICValidator().maxLength(11),
        internal_comment: new StringValidator().maxLength(1000),
        secret: new StringValidator().minLength(6).maxLength(32).required(),
    });

    if (needsBIC) {
        validatorShape.fields.bic.required();
    }
    return validatorShape;
};

export const getBankAccountEditShape = (): ObjectValidator<any> => {
    const validatorShape = new ObjectValidator({
        name: new StringValidator().maxLength(255),
        internal_comment: new StringValidator().maxLength(1000),
    });

    validatorShape.setDropNotInShape(true);

    return validatorShape;
};
