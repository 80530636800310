type GlobalConfigType = {
    customerApiUrl?: string;
    customerApiUrlBase?: string;
    customerApiSocketUrl?: string;
    customerApiCredentialsMode?: string;
    transactionApiUrl?: string;
    titlePrefix?: string;
    system?: string;
    enableLogin?: boolean;
    enableSearch?: boolean;
    [key: string | number]: any;
};

export const setGlobalConfig = (props: any): void => {
    globalConfig.customerApiUrl = Boolean(props.customerApiUrl)
        ? (props.customerApiUrl as string) + '/api/client'
        : 'http://127.0.0.1' + '/api/client';

    globalConfig.customerApiUrlBase = Boolean(props.customerApiUrl)
        ? (props.customerApiUrl as string)
        : 'http://127.0.0.1';

    globalConfig.customerApiSocketUrl = Boolean(props.customerApiSocketUrl)
        ? (props.customerApiSocketUrl as string)
        : 'http://127.0.0.1';

    globalConfig.transactionApiUrl = Boolean(props.transactionApiUrl)
        ? (props.transactionApiUrl as string)
        : 'http://127.0.0.1';

    globalConfig.customerApiCredentialsMode = 'include';

    globalConfig.system = Boolean(props.system) ? (props.system as string) : 'production';

    globalConfig.enableSearch = Boolean(props.enableSearch) ? props.enableSearch === 'true' : false;
    globalConfig.enableLogin = Boolean(props.enableLogin) ? props.enableLogin === 'true' : false;

    if (Boolean(globalConfig.system)) {
        switch (globalConfig.system) {
            case 'testing':
                globalConfig.titlePrefix = '(TESTING) ';
                break;
            case 'staging':
                globalConfig.titlePrefix = '(STAGING) ';
                break;
            case 'development':
                globalConfig.customerApiCredentialsMode = 'include';
                globalConfig.titlePrefix = '(DEV) ';
                break;
            default:
                break;
        }
    }
};

export const globalConfig: GlobalConfigType = {};
