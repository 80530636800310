import React from 'react';
import {Button, Card, CardContent, CardHeader, Grid} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import CenteredCircularProgress from '@/Components/CenteredCircularProgress';
import {SimplifiedConnectorWithLocationInfo} from '@/Types/ChargeTypes';
import {fetchWithRetry, withCredentialsAndContentType} from '@/Helpers/fetchHelpers';
import {globalConfig} from '@/Helpers/globalConfig';

type WaitingProps = {
    connector: SimplifiedConnectorWithLocationInfo,
    setTransactionHasFailed: React.Dispatch<React.SetStateAction<boolean>>,
};

const Waiting = (props: WaitingProps): React.JSX.Element => {
    const {connector, setTransactionHasFailed} = props;
    const {t} = useTranslation(['connector']);
    const timeout = React.useRef<number | NodeJS.Timeout>();
    const [retryShown, setRetryShown] = React.useState(false);
    const navigate = useNavigate();

    React.useEffect(() => {
        timeout.current = setTimeout(() => {
            setRetryShown(true);
        }, 20000);

        return () => {
            if (timeout.current) {
                clearTimeout(timeout.current);
            }
        };
    }, []);

    const handleRemoteStart = (): void => {
        setRetryShown(false);
        fetchWithRetry(
            (globalConfig.customerApiUrl as string) + '/transactions/current/remote-start',
            withCredentialsAndContentType({
                method: 'POST',
            }),
        )
            .then(() => {
                timeout.current = setTimeout(() => {
                    setRetryShown(true);
                }, 20000);
            })
            .catch((reason) => {
                console.error('Could not trigger remote start', reason);
                setTransactionHasFailed(true);
            });
    };

    const handleCancel = (): void => {
        fetchWithRetry(
            (globalConfig.customerApiUrl as string) + '/transactions/current',
            withCredentialsAndContentType({
                method: 'DELETE',
            }),
        )
            .catch((reason) => console.error('Could not cancel charge process', reason))
            .finally(() => navigate('/go/' + connector.uid));
    };

    const plugging = connector.status === 'AVAILABLE';

    return <Card>
        <CardHeader title={plugging ? t('connector:plugging') : t('connector:waiting')}/>
        <CardContent>
            {plugging
                ? <p>{t('connector:plugging_long')}</p>
                : <>
                    <p>{t('connector:waiting_long')}</p>
                    <CenteredCircularProgress/>
                </>}
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Button fullWidth variant="contained" color="error" onClick={handleCancel}>
                        {t('connector:cancel')}
                    </Button>
                </Grid>
                {retryShown && <Grid item xs={12}>
                    <Button variant="contained" color="secondary" fullWidth onClick={handleRemoteStart}>
                        {t('connector:retry_start')}
                    </Button>
                </Grid>}
            </Grid>
        </CardContent>
    </Card>;
};

export default Waiting;
