import React from 'react';
import {FormiflyForm, ObjectValidator, SubmitFunction} from 'formifly';
import {TFunction} from 'i18next';
import {Alert, CardContent, Grid, Typography} from '@mui/material';
import {FormiflyMuiField} from '@common/butterfly-shared-react-library';
import PasswordInputField from '@/Areas/Auth/Components/PasswordInputField';
import {
    FlexGrowButton,
    MarginGrid,
    SubmitCancelGrid,
    TextWrappableCardHeader,
} from '@/Areas/Customer/Components/AccountSecurityOverviewComponents';
import ModalBody from '@/Components/Layout/ModalBody';
import CommonModal from '@/Components/Layout/CommonModal';

type WebAuthNModalsProps = {
    t: TFunction<['account', 'common', 'auth']>,
    createWebAuthNModal: any,
    setCreateWebAuthNModal: (b: boolean) => void,
    createWebAuthNDeviceShape: any,
    handleCreateWebAuthNDeviceSubmit: SubmitFunction<ObjectValidator<any>>,
    deleteWebAuthNDevice: any,
    deleteWebAuthNDeviceShape: any,
    deleteLastDevice: boolean,
    handleDeleteWebAuthNDeviceClose: () => void,
    handleDeleteWebAuthNDeviceSubmit: SubmitFunction<ObjectValidator<any>>,
    editWebAuthNDevice: any,
    editWebAuthNDeviceShape: any,
    handleEditWebAuthNDeviceClose: () => void,
    handleEditWebAuthNDeviceSubmit: SubmitFunction<ObjectValidator<any>>,
}

const WebAuthNModals = (props: WebAuthNModalsProps): React.JSX.Element => {
    const {
        t,
        createWebAuthNModal,
        setCreateWebAuthNModal,
        createWebAuthNDeviceShape,
        handleCreateWebAuthNDeviceSubmit,
        deleteWebAuthNDevice,
        deleteWebAuthNDeviceShape,
        deleteLastDevice,
        handleDeleteWebAuthNDeviceClose,
        handleDeleteWebAuthNDeviceSubmit,
        editWebAuthNDevice,
        handleEditWebAuthNDeviceSubmit,
        editWebAuthNDeviceShape,
        handleEditWebAuthNDeviceClose,
    } = props;

    return <>
        <CommonModal open={createWebAuthNModal} onClose={() => setCreateWebAuthNModal(false)}>
            <ModalBody>
                <TextWrappableCardHeader title={t('security.webauthn.creation.header')}/>
                <CardContent>
                    <Grid item xs={12}>
                        <FormiflyForm shape={createWebAuthNDeviceShape} onSubmit={handleCreateWebAuthNDeviceSubmit}>
                            <MarginGrid item xs={12}>
                                <Typography>
                                    {t('security.webauthn.creation.info')}
                                </Typography>
                            </MarginGrid>
                            <MarginGrid item xs={12}>
                                <FormiflyMuiField name="label" label={t('security.device.label')} id="label"/>
                            </MarginGrid>
                            <MarginGrid item xs={12}>
                                <PasswordInputField name="password" label={t('auth:password')} t={t}/>
                            </MarginGrid>
                            <MarginGrid item xs={12}>
                                <PasswordInputField name="password_repeat" label={t('auth:password_repeat')} t={t}/>
                            </MarginGrid>
                            <SubmitCancelGrid item xs={12}>
                                <FlexGrowButton type="submit"
                                                variant="contained">{t('security.device.create', {what: t('security.webauthn.device')})}</FlexGrowButton>
                                <FlexGrowButton onClick={() => setCreateWebAuthNModal(false)} color="error"
                                                variant="contained">{t('common:cancel')}</FlexGrowButton>
                            </SubmitCancelGrid>
                        </FormiflyForm>
                    </Grid>
                </CardContent>
            </ModalBody>
        </CommonModal>
        <CommonModal open={Boolean(deleteWebAuthNDevice)} onClose={handleDeleteWebAuthNDeviceClose}>
            <ModalBody>
                <TextWrappableCardHeader
                    title={t('security.webauthn.deletion.header', {what: deleteWebAuthNDevice ? deleteWebAuthNDevice!.name : t('security.webauthn.device')})}/>
                <CardContent>
                    <Grid item xs={12}>
                        <FormiflyForm shape={deleteWebAuthNDeviceShape} onSubmit={handleDeleteWebAuthNDeviceSubmit}>
                            {deleteLastDevice
                                && <MarginGrid item xs={12}>
                                    <Alert severity='warning' variant='outlined'>
                                        <Typography>
                                            {t('security.webauthn.deletion.info_last')}
                                        </Typography>
                                    </Alert>
                                </MarginGrid>}
                            <MarginGrid item xs={12}>
                                <Typography>
                                    {t('security.webauthn.deletion.info')}
                                </Typography>
                            </MarginGrid>
                            <MarginGrid item xs={12}>
                                <PasswordInputField name="password" label={t('auth:password')} t={t}/>
                            </MarginGrid>
                            <MarginGrid item xs={12}>
                                <PasswordInputField name="password_repeat" label={t('auth:password_repeat')} t={t}/>
                            </MarginGrid>
                            <SubmitCancelGrid item xs={12}>
                                <FlexGrowButton type="submit" variant="contained">{t('security.device.delete')}</FlexGrowButton>
                                <FlexGrowButton onClick={() => handleDeleteWebAuthNDeviceClose()} color="error"
                                                variant="contained">{t('common:cancel')}</FlexGrowButton>
                            </SubmitCancelGrid>
                        </FormiflyForm>
                    </Grid>
                </CardContent>
            </ModalBody>
        </CommonModal>
        <CommonModal open={Boolean(editWebAuthNDevice)} onClose={handleEditWebAuthNDeviceClose}>
            <ModalBody>
                <TextWrappableCardHeader
                    title={t('security.webauthn.editing.header', {what: editWebAuthNDevice ? editWebAuthNDevice!.name : t('security.webauthn.device')})}/>
                <CardContent>
                    <Grid item xs={12}>
                        <FormiflyForm shape={editWebAuthNDeviceShape} onSubmit={handleEditWebAuthNDeviceSubmit}>
                            <MarginGrid item xs={12}>
                                <Typography>
                                    {t('security.webauthn.editing.info')}
                                </Typography>
                            </MarginGrid>
                            <MarginGrid item xs={12}>
                                <FormiflyMuiField name="label" label={t('security.device.label')} id="label"/>
                            </MarginGrid>
                            <SubmitCancelGrid item xs={12}>
                                <FlexGrowButton type="submit" variant="contained">{t('security.device.edit')}</FlexGrowButton>
                                <FlexGrowButton onClick={() => handleEditWebAuthNDeviceClose()} color="error"
                                                variant="contained">{t('common:cancel')}</FlexGrowButton>
                            </SubmitCancelGrid>
                        </FormiflyForm>
                    </Grid>
                </CardContent>
            </ModalBody>
        </CommonModal>
    </>;
};

export default WebAuthNModals;
