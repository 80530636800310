import styled from 'styled-components';
import {Button, ListItemIcon, MenuItem} from '@mui/material';
import {Link} from 'react-router-dom';

export const LeftAlignedButton = styled(Button)`
    justify-content: start;
    min-width: fit-content;
`;

export const UnStyledLink = styled(Link)`
    color: inherit;
`;
export const LeftMenuItem = styled(MenuItem)`
    padding-left: 6px;
`;

export const PrimaryColorListItemIcon = styled(ListItemIcon)`
    color: ${props => props.theme.palette.text.primary as string};
`;

export const FullwidthButton = styled(Button)`
    width: 100%;
    justify-content: start;
`;

export const FitContentButton = styled(Button)`
    min-width: fit-content;
    justify-content: center;
`;
