import React from 'react';
import {useTranslation} from 'react-i18next';
import {AddRounded, EditRounded} from '@mui/icons-material';
import {Link} from 'react-router-dom';
import {IconButtonWithTooltip, LowercaseButton} from '@common/butterfly-shared-react-library';
import {Card, CardContent, CardHeader, Grid, Table, TableBody, TableCell, TableRow} from '@mui/material';
import withLoginRequirement from '@/Components/withLoginRequirement';
import {Token} from '@/Areas/Payment/Data/Token';
import {callWithJwt} from '@/Helpers/jwtHelpers';
import GenericErrorCard from '@/Components/GenericErrorCard';
import CenteredCircularProgress from '@/Components/CenteredCircularProgress';
import TableRowHeading from '@/Components/Tables/TableRowHeading';
import ConfirmPaymentMethodDeletion from '@/Areas/Payment/Components/ConfirmPaymentMethodDeletion';
import LessPaddedCardHeader from '@/Components/Layout/LessPaddedCardHeader';
import {BankAccount} from '@/Areas/Payment/Data/BankAccount';
import {fetchBankAccounts, getBankAccountLabelFromId} from '@/Areas/Payment/Helpers/BankAccountHelpers';

const TokenOverview = (): React.JSX.Element => {
    const [tokens, setTokens] = React.useState<Token[]>();
    const [bankAccounts, setBankAccounts] = React.useState<BankAccount[]>();
    const [tokenToDelete, setTokenToDelete] = React.useState<Token>();
    const [deletionError, setDeletionError] = React.useState<string>();
    const [error, setError] = React.useState(false);
    const {t} = useTranslation(['common', 'payment', 'error']);

    React.useEffect(() => {
        callWithJwt<{items?: Token[]}>('/tokens', 'GET')
            .then((response) => {
                if (response.data.items) {
                    setTokens(response.data.items);
                }
            })
            .catch((reason) => {
                console.error('Could not fetch tokens', reason);
                setError(true);
            });

        void fetchBankAccounts(setBankAccounts, setError);
    }, []);

    const handleOpenDeleteConfirm = (toDelete: Token): void => {
        setTokenToDelete(toDelete);
    };

    const handleCloseDeleteConfirm = (): void => {
        setTokenToDelete(undefined);
    };

    const handleTokenDeletion = (): void => {
        callWithJwt('/tokens/' + String(tokenToDelete!.id), 'DELETE')
            .then(() => {
                setTokens((oldTokens: Token[] | undefined) => {
                    if (oldTokens === undefined) {
                        return oldTokens;
                    }
                    return oldTokens!.filter((token) => token.id !== tokenToDelete!.id);
                });
                handleCloseDeleteConfirm();
            })
            .catch((reason) => {
                console.error('Could not delete token', reason);
                setDeletionError('deletion_failed');
            });
    };

    if (error) {
        return <GenericErrorCard/>;
    }

    if (!tokens || !bankAccounts) {
        return <CenteredCircularProgress/>;
    }

    return <>
        <Card>
            <CardHeader title={t('payment:token.heading')}
                        action={<IconButtonWithTooltip label={t('payment:token.add')} component={Link}
                                                       to={'/account/payment/token/add'}>
                            <AddRounded/>
                        </IconButtonWithTooltip>}/>
            <CardContent>
                {tokens.length === 0
                    ? <p>{t('payment:token.none_found')}</p>
                    : <Grid container spacing={2}>
                        {tokens!.map((token, index) => <Grid item key={index}>
                            <Card>
                                <LessPaddedCardHeader
                                    title={token.name === '' ? t('payment:token.label') : token.name}
                                    action={<IconButtonWithTooltip label={t('payment:token.edit')}
                                                                   component={Link}
                                                                   to={'/account/payment/token/' + String(token.id) + '/edit'}>
                                        <EditRounded/>
                                    </IconButtonWithTooltip>}/>
                                <CardContent>
                                    <Table size="small">
                                        <TableBody>
                                            <TableRow>
                                                <TableRowHeading>{t('payment:identifier')}:</TableRowHeading>
                                                <TableCell>{token.identifier}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableRowHeading>{t('payment:bank_account')}</TableRowHeading>
                                                <TableCell>
                                                    {getBankAccountLabelFromId(token.account_id, bankAccounts)}
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableRowHeading>{t('common:internal_comment')}:</TableRowHeading>
                                                <TableCell>{token.internal_comment}</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                    <br/>
                                    <LowercaseButton color="error"
                                                     variant="contained"
                                                     fullWidth
                                                     onClick={() => handleOpenDeleteConfirm(token)}>
                                        {t('payment:token.delete')}
                                    </LowercaseButton>
                                </CardContent>
                            </Card>
                        </Grid>)}
                    </Grid>}
            </CardContent>
        </Card>
        <ConfirmPaymentMethodDeletion t={t}
                                      which="token"
                                      error={deletionError}
                                      onClose={handleCloseDeleteConfirm}
                                      onDelete={handleTokenDeletion}
                                      toDelete={tokenToDelete}/>
    </>;
};

export default withLoginRequirement(TokenOverview);
