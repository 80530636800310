import React from 'react';
import {useUserContext} from '@/Contexts/User/UserContext';
import {StyledCard} from '@/Components/Layout/Common';
import withLoginRequirement from '@/Components/withLoginRequirement';

const AccountOverview = (): React.JSX.Element => {
    const {user} = useUserContext();

    return <StyledCard>{JSON.stringify(user)}</StyledCard>;
};

export default withLoginRequirement(AccountOverview);
