import React from 'react';
import {Article, LinkedIn, X} from '@mui/icons-material';
import styled from 'styled-components';
import {useTranslation} from 'react-i18next';

import {BaseHeadline} from '@/Components/Layout/Common';

const SocialWrapper = styled.div`
    width: 40%;
    float: left;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;

    & > p {
        font-size: x-small;
    }

    @media (max-width: ${props => props.theme.breakpoints.values.md as string}px) {
        width: 100%;

        & > p {
            font-size: small;
        }
    }
`;

const SocialList = styled.ul`
    max-width: 23rem;
    color: #000;
    hyphens: manual;
    margin: 0;
    padding: 0;

    box-sizing: border-box;
`;

const SocialItem = styled.li`
    float: left;
    list-style: none;
    padding: 0 1.25rem 1.25rem 0;

    box-sizing: border-box;
`;

const SocialLabel = styled.span`
    margin-top: 0.25rem;
    display: inline-block;
    width: 100%;
    text-align: center;
    color: initial;
`;

const SocialIcon = styled.div`
    border-radius: 50%;
    color: #fff;
    display: block;
    height: 4.25rem;
    position: relative;
    text-align: center;
    text-decoration: none;
    width: 4.25rem;

    & svg {
        display: inline-block;
        line-height: 1;
        position: absolute;
        top: 50%;
        transform: translateY(-50%) translateX(-50%);
        left: 50%;
    }

    & svg {
        max-width: 32px;
        max-height: 32px;
    }

    & svg path {
        fill: currentColor;
    }
`;

SocialIcon.defaultProps = {
    'aria-hidden': true,
};

const SocialLink = styled.a<{
    $color?: string;
    $hoverColor?: string;
}>`
    cursor: pointer;

    &:hover span {
        color: #8daa0b;
        text-decoration: underline;
    }

    & div {
        background-color: ${props => props.$color};
    }

    &:hover div {
        background-color: ${props => props.$hoverColor};
    }

    &:hover div:after {
        opacity: 1;
        transform: none;
        visibility: visible;
    }
`;

SocialLink.defaultProps = {
    $color: '#afc91c',
    $hoverColor: '#70d31e',
};

const SocialMedia = (): React.JSX.Element => {
    const {t} = useTranslation('footer');
    return (
        <SocialWrapper>
            <BaseHeadline>{t('social_media.root')}</BaseHeadline>
            <SocialList>
                <SocialItem>
                    <SocialLink
                        href="https://www.gls-mobility.de/"
                        title={t('social_media.blog.title')}
                        target="_blank"
                        rel="noopener noreferrer"
                        $color="#afc91c"
                    >
                        <SocialIcon>
                            <Article/>
                        </SocialIcon>
                        <SocialLabel>{t('social_media.blog.label')}</SocialLabel>
                    </SocialLink>
                </SocialItem>
                <SocialItem>
                    <SocialLink href="https://x.com/GLSgiroe" title={t('social_media.twitter.title')} target="_blank">
                        <SocialIcon>
                            <X/>
                        </SocialIcon>
                        <SocialLabel>{t('social_media.twitter.label')}</SocialLabel>
                    </SocialLink>
                </SocialItem>
                <SocialItem>
                    <SocialLink
                        href="https://www.linkedin.com/company/gls-mobility-gmbh/"
                        title={t('social_media.linkedIn.title')}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <SocialIcon>
                            <LinkedIn/>
                        </SocialIcon>
                        <SocialLabel>{t('social_media.linkedIn.label')}</SocialLabel>
                    </SocialLink>
                </SocialItem>
            </SocialList>
            <p>{t('social_media.privacy')}</p>
        </SocialWrapper>
    );
};

export default SocialMedia;
