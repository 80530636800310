import React, {Suspense} from 'react';
import {render} from 'react-dom';
import Root from './Components/Root';
import '../html/index.css';
import './Extensions/i18n';
import LoadingCircle from './Components/LoadingCircle';
import BaseErrorBoundary from '@/Components/Layout/BaseErrorBoundary';

const root = document.getElementById('root');
if (root) {
    render(
        <Suspense fallback={<LoadingCircle/>}>
            <BaseErrorBoundary>
                <Root
                    customerApiUrl={root.getAttribute('data-api-url')}
                    customerApiSocketUrl={root.getAttribute('data-socketio-url')}
                    transactionApiUrl={root.getAttribute('data-trans-url')}
                    system={root.getAttribute('data-system')}
                    enableLogin={root.getAttribute('data-enable-login')}
                    enableSearch={root.getAttribute('data-enable-search')}
                />
            </BaseErrorBoundary>
        </Suspense>,
        root,
    );
}
