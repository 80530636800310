import React from 'react';
import {CardContent, CardHeader} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {ConnectorError, PaymentError, PricegroupError} from '@/Areas/Connector/Data/ConnectorTypes';
import {StyledCard} from '@/Components/Layout/Common';

type ErrorDisplayProps = {
    error: PricegroupError | ConnectorError | PaymentError;
}

const ErrorDisplay = (props: ErrorDisplayProps): React.JSX.Element => {
    const {error} = props;
    const {t} = useTranslation(['connector', 'common', 'wallee']);

    let errorMsg;
    let title = t('common:error');
    if (error === ConnectorError.NOT_FOUND) {
        errorMsg = t('connector:connector_not_found');
        title = t('error:not_found', {what: t('connector:connector')} as any) as string;
    } else if (error === PricegroupError.NOT_AVAILABLE) {
        errorMsg = t('connector:no_remote_start');
    } else if (error === ConnectorError.NETWORK_ERROR || error === PricegroupError.NETWORK_ERROR) {
        errorMsg = t('connector:network_error');
    } else if (error === PaymentError.NO_PAYMENT_METHODS) {
        errorMsg = t('wallee:error.no_payment_methods');
    } else if (error === PaymentError.NO_TRANSACTION_CREATED) {
        errorMsg = t('wallee:error.no_transaction_created');
    } else if (error === PaymentError.UNEXPECTED_INTERNAL) {
        errorMsg = t('wallee:error.unexpected_internal');
    } else if (error === PaymentError.NOT_CONFIRMED) {
        errorMsg = t('wallee:error.transaction_not_confirmed');
    } else {
        // This never actually happens
        errorMsg = t('connector:unexpected_error');
    }

    return <StyledCard>
        <CardHeader title={title}/>
        <CardContent>{errorMsg}</CardContent>
    </StyledCard>;
};

export default ErrorDisplay;
