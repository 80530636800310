import {AccountCircle, Face2, Key, Login as LoginIcon, Logout as LogoutIcon, Payments, RecentActors} from '@mui/icons-material';
import {useNavigate} from 'react-router-dom';
import React from 'react';
import {ListItemText, Menu, MenuItem, MenuList} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {LeftMenuItem, PrimaryColorListItemIcon, UnStyledLink} from '@/Components/MenuComponents';
import {LoggedInState, useUserContext} from '@/Contexts/User/UserContext';
import {NavbarPopupMenu} from '@/Components/Layout/Common';
import {getUserAvatarName} from '@/Areas/Customer/Helpers/CustomerHelpers';
import UserAvatar from '@/Areas/Customer/Components/UserAvatar';

const AccountMenuItem = (): React.JSX.Element => {
    const [anchorEl, setAnchorEl] = React.useState<Element | null>(null);

    const {t} = useTranslation('common');

    const {user, loggedInState, attemptLogout} = useUserContext();

    const [userAvatarName, setUserAvatarName] = React.useState<string>('');

    React.useEffect(() => {
        if (user) {
            setUserAvatarName(getUserAvatarName(user.name));
        }
    }, [user]);

    const navigate = useNavigate();

    const handleLoginClick = (): void => {
        navigate('/login');
    };

    const handleAccountClick = (event: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement | HTMLLIElement, MouseEvent>): void => {
        setAnchorEl(event.currentTarget ?? null);
    };

    const handleClose = (): void => {
        setAnchorEl(null);
    };

    const handleAccountSelectionClick = (): void => {
        navigate('/account');
        handleClose();
    };

    const handleBankAccountClick = (): void => {
        navigate('/account/payment/bank');
        handleClose();
    };

    const handleTokenClick = (): void => {
        navigate('/account/payment/token');
        handleClose();
    };

    const handleSecurityClick = (): void => {
        navigate('/account/security');
        handleClose();
    };

    const handleAccountLogoutClick = (): void => {
        attemptLogout().catch((reason) => console.log(reason));
        handleClose();
    };

    return <>
        {loggedInState === LoggedInState.LoggedIn
            ? <>
                <LeftMenuItem onClick={handleAccountClick}>
                    {Boolean(userAvatarName)
                        ? <PrimaryColorListItemIcon><UserAvatar>{userAvatarName}</UserAvatar></PrimaryColorListItemIcon>
                        : <PrimaryColorListItemIcon><Face2/></PrimaryColorListItemIcon>
                    }
                    <ListItemText>
                        {t('header.account')}
                    </ListItemText>
                </LeftMenuItem>
                <NavbarPopupMenu>
                    <Menu
                        id="account-menu"
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                        transitionDuration={0}
                    >
                        <MenuList>
                            <MenuItem onClick={handleAccountSelectionClick}>
                                <PrimaryColorListItemIcon><AccountCircle/></PrimaryColorListItemIcon>
                                <ListItemText>
                                    {t('account.my_account')}
                                </ListItemText>
                            </MenuItem>
                            <MenuItem onClick={handleBankAccountClick}>
                                <PrimaryColorListItemIcon><Payments/></PrimaryColorListItemIcon>
                                <ListItemText>
                                    {t('account.payment.bank')}
                                </ListItemText>
                            </MenuItem>
                            <MenuItem onClick={handleTokenClick}>
                                <PrimaryColorListItemIcon><RecentActors/></PrimaryColorListItemIcon>
                                <ListItemText>
                                    {t('account.payment.token')}
                                </ListItemText>
                            </MenuItem>
                            <MenuItem onClick={handleSecurityClick}>
                                <PrimaryColorListItemIcon><Key/></PrimaryColorListItemIcon>
                                <ListItemText>
                                    {t('account.security')}
                                </ListItemText>
                            </MenuItem>
                            <MenuItem onClick={handleAccountLogoutClick}>
                                <PrimaryColorListItemIcon>
                                    <LogoutIcon/>
                                </PrimaryColorListItemIcon>
                                <ListItemText>
                                    {t('account.logout')}
                                </ListItemText>
                            </MenuItem>
                        </MenuList>
                    </Menu>
                </NavbarPopupMenu>
            </>
            : <LeftMenuItem onClick={handleLoginClick}>
                <PrimaryColorListItemIcon><LoginIcon/></PrimaryColorListItemIcon>
                <ListItemText><UnStyledLink to="/login">{t('header.login')}</UnStyledLink></ListItemText>
            </LeftMenuItem>
        }
    </>;
};

export default AccountMenuItem;
