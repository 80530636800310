import React from 'react';
import {Card, CardContent, CardHeader} from '@mui/material';
import {Translation} from 'react-i18next';

const GenericErrorCard = (): React.JSX.Element => {
    return <Translation ns={'error'}>
        {t => (
            <Card>
                <CardHeader title={t('heading')}/>
                <CardContent>
                    <p>
                        {t('description')}
                        <br/>
                        {t('action')}
                        <br/>
                        {t('persisting')}
                    </p>
                    <p>{t('calming_info')}</p>
                </CardContent>
            </Card>
        )}
    </Translation>;
};

export default GenericErrorCard;
