import React from 'react';
import {useTranslation} from 'react-i18next';
import {Card, CardContent, CardHeader} from '@mui/material';
import withLoginRequirement from '@/Components/withLoginRequirement';
import TokenForm from '@/Areas/Payment/Helpers/TokenForm';

type TokenAddProps = {
    auth?: string,
}

export const TokenAddWithoutHoc = (props: TokenAddProps): React.JSX.Element => {
    const {t} = useTranslation(['payment', 'formifly', 'common']);

    return <Card>
        <CardHeader title={t('payment:token.add')}/>
        <CardContent>
            <TokenForm edit={false} t={t} auth={props.auth} submitUrl="/tokens"/>
        </CardContent>
    </Card>;
};

const TokenAddWithHoc = withLoginRequirement(TokenAddWithoutHoc as any);
export default TokenAddWithHoc;
