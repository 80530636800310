import {NumberValidator, ObjectValidator, StringValidator} from 'formifly';
import {TFunction} from 'i18next';

export type Token = {
    identifier: string,
    account_id: number,
    id: number,
    name?: string,
    internal_comment?: string,
};

export const getTokenShape = (edit: boolean, t: TFunction<any>, auth?: string): ObjectValidator<any> => {
    const shape: ObjectValidator<any> = new ObjectValidator({
        account_id: new NumberValidator(true).required(),
        name: new StringValidator().maxLength(255).required(),
        internal_comment: new StringValidator().maxLength(1000),
    });

    if (!edit) {
        if (auth) {
            shape.fields.auth = new StringValidator(auth).maxLength(255);
        } else {
            shape.fields.identifier = new StringValidator()
                .regex(/^[a-fA-F\d]{8}([a-fA-F\d]{6}([a-fA-F\d]{6})?)?$/, t('formifly:static_token'))
                .required();
        }
    }

    shape.setDropNotInShape(true);
    return shape;
};
