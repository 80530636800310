import React from 'react';
import Home from './Home';
import Search from '@/Areas/Transaction/Views/TransactionSearch';

const HomeSwitch = (props: any): React.JSX.Element => {
    if (location.hostname === 'pay-t.io') {
        return <Search {...props} />;
    }

    return <Home {...props} />;
};

export default HomeSwitch;
