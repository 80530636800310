import {Card} from '@mui/material';
import styled from 'styled-components';

const ModalBody = styled(Card)`
    overflow: visible;
    max-height: 95%;
    position: absolute;
    padding: 1rem 0 0 0;
    margin: 1rem auto 1rem auto;
    top: 0;
    right: 0;
    left: 0;
    width: fit-content;
`;

export default ModalBody;
