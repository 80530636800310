import React from 'react';
import {Backdrop, CircularProgress} from '@mui/material';

const LoadingCircle = (): React.JSX.Element => {
    return (
        <Backdrop open={true} invisible={true}>
            <CircularProgress data-testid="main-circular-progress"/>
        </Backdrop>
    );
};

export default LoadingCircle;
