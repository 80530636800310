import {storageFallback} from './storageFallback';

export const getItem = (name: string, initial?: any): any => {
    // Depending on the user's browser's weirdness, either localStorage, sessionStorage, or in the worst case a global object will be used to store data
    const storageBackendFunctions = [
        () => window.localStorage.getItem(name),
        () => window.sessionStorage.getItem(name),
        (): any => storageFallback[name],
    ];

    let item = null;
    let c = 0;
    while (c < 3 && (item === null || item === undefined || item === '' || item === 'null')) {
        item = storageBackendFunctions[c]();
        c++;
    }

    if (item === null || item === undefined || item === '' || item === 'null') {
        return initial;
    }

    // Deserialize json; will throw an error on non-json strings which will then be returned normally
    try {
        return JSON.parse(item);
    } catch {
        return item;
    }
};

export const setItem = (name: string, value: any): void => {
    let setTo;
    if (['object', 'array'].includes(typeof (value))) {
        setTo = JSON.stringify(value);
    } else {
        setTo = value;
    }

    try {
        window.localStorage.setItem(name, setTo);
    } catch {
        try {
            // In case local storage is not available, fall back to session storage
            window.sessionStorage.setItem(name, setTo);
        } catch {
            // If session storage is not available either, fall back to a global singleton
            storageFallback[name] = setTo;
        }
    }
};

export const removeItem = (name: string): void => {
    window.localStorage.removeItem(name);
    window.sessionStorage.removeItem(name);
    storageFallback[name] = null;
};

export const clearStorage = (): void => {
    window.localStorage.clear();
    window.sessionStorage.clear();

    for (const index in storageFallback) {
        storageFallback[index] = null;
    }
};
