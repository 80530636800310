import styled from 'styled-components';
import {Button, CardHeader, Grid} from '@mui/material';

export const MarginGrid = styled(Grid)`
    margin-bottom: 1rem;
`;

export const SubmitCancelGrid = styled(Grid)`
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-items: stretch;
    justify-content: stretch;

    gap: 1rem;
`;

export const FlexGrowButton = styled(Button)`
    flex-grow: 1;
`;

export const ActionButtonContainer = styled.div`
    display: inline-flex;
    gap: .5rem;
    height: 100%;
`;

export const ActionAreaContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-right: .5rem;
`;

export const TextWrappableCardHeader = styled(CardHeader)`
    overflow-wrap: anywhere;
`;
