import {Alert, AlertColor, Button, Card, CardContent, Grid} from '@mui/material';
import {FormHelpers, FormiflyMuiField} from '@common/butterfly-shared-react-library';
import {FormiflyForm, ValueOfValidator} from 'formifly';
import React from 'react';
import {TFunction} from 'i18next';
import {Link, useNavigate} from 'react-router-dom';
import {Trans} from 'react-i18next';
import {FormiflySelectOption} from '@/Types/Formifly';
import {fetchBankAccounts, getBankAccountLabel} from '@/Areas/Payment/Helpers/BankAccountHelpers';
import {BankAccount} from '@/Areas/Payment/Data/BankAccount';
import GenericErrorCard from '@/Components/GenericErrorCard';
import CenteredCircularProgress from '@/Components/CenteredCircularProgress';
import {getTokenShape, Token} from '@/Areas/Payment/Data/Token';
import {callWithJwt} from '@/Helpers/jwtHelpers';
import {AlertState} from '@/Types/Alert';
import PaddedGridItem from '@/Components/Layout/PaddedGridItem';

type TokenFormProps = {
    t: TFunction<['payment', 'formifly', 'common']>,
    default?: Partial<Token>,
    edit: boolean,
    submitUrl: string,
    auth?: string,
}

const TokenForm = (props: TokenFormProps): React.JSX.Element => {
    const [bankAccounts, setBankAccounts] = React.useState<BankAccount[]>();
    const [bankAccountOptions, setBankAccountOptions] = React.useState<FormiflySelectOption[]>();
    const [error, setError] = React.useState(false);
    const [alert, setAlert] = React.useState<AlertState>({show: false, message: '', severity: 'info'});

    const {edit, auth, submitUrl, t} = props;
    const navigate = useNavigate();

    React.useEffect(() => {
        void fetchBankAccounts(setBankAccounts, setError);
    }, []);

    React.useEffect(() => {
        if (bankAccounts) {
            setBankAccountOptions(bankAccounts.map((bankAccount) => ({
                value: bankAccount.id,
                label: getBankAccountLabel(bankAccount),
            })));
        }
    }, [bankAccounts]);

    if (error) {
        return <GenericErrorCard/>;
    }

    if (!bankAccountOptions) {
        return <CenteredCircularProgress/>;
    }

    if (bankAccountOptions.length === 0) {
        return <Card>
            <CardContent>
                <p>
                    {t('payment:token.no_accounts')}
                    <Link to={'/account/payment/bank/add'}>{t('payment:token.no_accounts_link')}</Link>
                    {t('payment:token.no_accounts_post')}
                </p>
            </CardContent>
        </Card>;
    }

    const shape = getTokenShape(edit, t, auth);

    const handleSubmit =
        (values: ValueOfValidator<typeof shape> | undefined, setErrors: React.Dispatch<React.SetStateAction<any>>): Promise<void> => {
            return callWithJwt(submitUrl, edit ? 'PATCH' : 'POST', values)
                .then(() => {
                    navigate('/account/payment/token');
                })
                .catch((reason) => {
                    if (reason.data.error.code === 'validation_error') {
                        if (reason.data.error.data.field_errors.auth) {
                            setAlert({show: true, message: 'error.token_auth_incorrect', severity: 'error'});
                        } else {
                            const validataclassErrors = FormHelpers.unpackValidataclassErrors(reason.data.error.data, t);
                            setErrors(validataclassErrors);
                        }
                    } else if (reason.data.error.code === 'token_identifier_unavailable') {
                        setAlert({show: true, message: 'error.token_identifier_unavailable', severity: 'error'});
                    } else if (reason.data.error.code === 'invalid_token_auth') {
                        setAlert({show: true, message: 'error.token_auth_incorrect', severity: 'error'});
                    } else {
                        console.error('could not submit token', reason);
                        setAlert({show: true, message: 'error.token_submit_failed', severity: 'error'});
                    }
                });
        };

    return <>
        <FormiflyForm t={t as any} shape={shape} onSubmit={handleSubmit} defaultValues={props.default as any}>
            <Grid container spacing={2}>
                {alert.show && <PaddedGridItem xs={12}>
                    <Alert severity={alert.severity as AlertColor}>
                        <Trans t={t} ns="payment">{alert.message}</Trans>
                    </Alert>
                </PaddedGridItem>}
                <Grid item xs={12}>
                    <FormiflyMuiField name="name" label={t('payment:name')}/>
                </Grid>
                {!edit && auth === undefined && <Grid item xs={12}>
                    <FormiflyMuiField name="identifier"
                                      label={t('payment:identifier')}
                                      help={t('payment:token.identifier_help')}
                                      wrapHelp/>
                </Grid>}
                <Grid item xs={12}>
                    <FormiflyMuiField name="account_id"
                                      label={t('payment:bank_account')}
                                      options={bankAccountOptions}/>
                </Grid>
                <Grid item xs={12}>
                    <FormiflyMuiField name="internal_comment" label={t('payment:internal_comment')}/>
                </Grid>
                <Grid item xs={6}>
                    <Button color="primary" type="submit" variant="contained" fullWidth>
                        {t('payment:token.save')}
                    </Button>
                </Grid>
                <Grid item xs={6}>
                    <Button to="/account/payment/token" component={Link} variant="contained" color="error" fullWidth>
                        {t('common:cancel')}
                    </Button>
                </Grid>
            </Grid>
        </FormiflyForm>
    </>;
};

export default TokenForm;
