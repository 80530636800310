import styled from 'styled-components';
import {Grid} from '@mui/material';
import React from 'react';

const PaddedGridStyled = styled(Grid)`
    padding: 0 0 ${props => props.padding as any}px 0;
`;

type PaddedGridItemProps = {
    padding?: number,
    children: React.JSX.Element,
    xs?: number,
    s?: number,
    md?: number,
    lg?: number,
    xl?: number,
}

const PaddedGridItem = (props: PaddedGridItemProps): React.JSX.Element => {
    return <PaddedGridStyled item {...props} />;
};

PaddedGridItem.defaultProps = {
    padding: 16,
};

export default PaddedGridItem;
