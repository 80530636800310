import React from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {SubmitFunction} from 'formifly';
import styled from 'styled-components';
import {Alert, AlertColor, Button, Card, CardContent, CardHeader, Grid, Typography} from '@mui/material';
import {IconButtonWithTooltip} from '@common/butterfly-shared-react-library';
import {DeleteOutlined, EditOutlined} from '@mui/icons-material';
import withLoginRequirement from '@/Components/withLoginRequirement';
import {
    createRegistrationCredential,
    createWebAuthNDevice,
    enableWebAuthNDevice,
    getCreateWebAuthNDeviceShape,
    getDeleteWebAuthNDeviceShape,
    getEditWebAuthNDeviceShape,
    webAuthNDevice,
    webAuthNResponse,
} from '@/Areas/Customer/Helpers/CustomerHelpers';
import CenteredCircularProgress from '@/Components/CenteredCircularProgress';
import {callWithJwt} from '@/Helpers/jwtHelpers';
import {getCurrentLocale} from '@/Extensions/i18n';
import {
    ActionAreaContainer,
    ActionButtonContainer,
    MarginGrid,
    TextWrappableCardHeader,
} from '@/Areas/Customer/Components/AccountSecurityOverviewComponents';
import WebAuthNModals from '@/Areas/Customer/Components/WebAuthNModals';

const AccountSecurityOverview = (): React.JSX.Element => {
    const {t} = useTranslation(['account', 'common', 'auth']);

    const [loading, setLoading] = React.useState(true);

    const [deleteLastDevice, setDeleteLastDevice] = React.useState(false);

    const [supportsWebAuthN, setSupportsWebAuthN] = React.useState(false);

    const [alert, setAlert] = React.useState({show: false, message: '', severity: 'info'});

    //const [totpDevices, setTotpDevices] = React.useState<totpDevice[]>([]);
    const [webauthnDevices, setWebauthnDevices] = React.useState<webAuthNDevice[]>([]);

    //const [createTotpModal, setCreateTotpModal] = React.useState(false);
    const [createWebAuthNModal, setCreateWebAuthNModal] = React.useState(false);

    //const [deleteTotpDevice, setDeleteTotpDevice] = React.useState<totpDevice>();
    const [deleteWebAuthNDevice, setDeleteWebAuthNDevice] = React.useState<webAuthNDevice>();

    //const [editTotpDevice, setEditTotpDevice] = React.useState<totpDevice>();
    const [editWebAuthNDevice, setEditWebAuthNDevice] = React.useState<webAuthNDevice>();

    //const createTotpDeviceShape = getCreateTotpDeviceShape();
    const createWebAuthNDeviceShape = getCreateWebAuthNDeviceShape();

    //const deleteTotpDeviceShape = getDeleteTotpDeviceShape();
    const deleteWebAuthNDeviceShape = getDeleteWebAuthNDeviceShape();

    //const editTotpDeviceShape = getEditTotpDeviceShape();
    const editWebAuthNDeviceShape = getEditWebAuthNDeviceShape();

    React.useEffect(() => {
        /*callWithJwt<totpResponse>('/totp-devices', 'GET')
            .then((response) => {
                if (response.data.total_count !== 0) {
                    setTotpDevices(response.data.items);
                } else {
                    setTotpDevices([]);
                }
                return Promise.resolve();
            })
            .catch((reason) => {
                console.error('TOTP Device fetch failed: ', reason);
                setAlert({show: true, message: 'security.error.totp.fetch', severity: 'error'});
            });
        */
        if (loading) {
            if (navigator.credentials) {
                setSupportsWebAuthN(true);
            }

            callWithJwt<webAuthNResponse>('/webauthn-devices', 'GET')
                .then((response) => {
                    if (response.data.total_count !== 0) {
                        setWebauthnDevices(response.data.items);
                    } else {
                        setWebauthnDevices([]);
                    }
                    return Promise.resolve();
                })
                .catch((reason) => {
                    console.error('WebAuthN Device fetch failed: ', reason);
                    setAlert({show: true, message: 'security.error.webauthn.fetch', severity: 'error'});
                });

            setLoading(false);
        }
    }, [loading]);

    React.useEffect(() => {
        // TODO: Check TOTP device length too
        if (webauthnDevices.length == 1) {
            setDeleteLastDevice(true);
        }

        // TODO: Do this when TOTP devices change
    }, [webauthnDevices]);

    const callDeleteWebAuthNDevice = async (device: any, password: string): Promise<any> => {
        if (device) {
            return callWithJwt('/webauthn-devices/' + String(device.id), 'DELETE', {password: password});
        }
        return Promise.reject('No device');
    };

    // const handleDeleteTotpModalClose = (): void => {
    //     setDeleteTotpDevice(undefined);
    // };

    const handleDeleteWebAuthNModalClose = (): void => {
        setDeleteWebAuthNDevice(undefined);
    };

    // const handleEditTotpModalClose = (): void => {
    //     setEditTotpDevice(undefined);
    // };

    const handleEditWebAuthNModalClose = (): void => {
        setEditWebAuthNDevice(undefined);
    };

    // const handleCreateTotpDeviceSubmit: SubmitFunction<typeof createTotpDeviceShape> = (values): void => {
    //     console.log(values);
    // };

    const handleCreateWebAuthNDeviceSubmit: SubmitFunction<typeof createWebAuthNDeviceShape> = async (values): Promise<void> => {
        if (values!.label) {
            const device = await createWebAuthNDevice(values!.label as unknown as string)
                .then(response => response)
                .catch((reason) => {
                    console.error('Could not create WebAuthN device: ', reason);
                    setAlert({show: true, message: 'security.error.webauthn.create', severity: 'error'});
                    setCreateWebAuthNModal(false);
                    setLoading(true);
                });
            if (device) {
                const credential = await createRegistrationCredential(device as any)
                    .then(response => response)
                    .catch((reason) => {
                        console.error('Could not create registration credential: ', reason);
                        callDeleteWebAuthNDevice(device, values!.password as unknown as string)
                            .then(() => {
                                setAlert({show: true, message: 'security.error.webauthn.create', severity: 'error'});
                            })
                            .catch((reason) => {
                                console.error('Could not delete WebAuthN Device: ', reason);
                            })
                            .finally(() => {
                                setCreateWebAuthNModal(false);
                                setLoading(true);
                            });
                    });
                if (credential) {
                    enableWebAuthNDevice({
                        password: values!.password,
                        id: device!.id,
                        credential: credential,
                    }).then((response) => {
                        if (response.data) {
                            if (response.data.enabled) {
                                setCreateWebAuthNModal(false);
                                setLoading(true);
                                setAlert({show: true, message: 'security.webauthn.creation.success', severity: 'success'});
                            }
                        }
                    }).catch((reason) => {
                        console.error('Could not enable WebAuthN device: ', reason);
                        setAlert({show: true, message: 'security.error.webauthn.enable', severity: 'error'});
                        setCreateWebAuthNModal(false);
                        setLoading(true);
                    });
                }
            }
        }
    };

    // const handleTotpEdit = (device: any): void => {
    //     console.log(device);
    // };

    const handleWebAuthNEdit = (device: any): void => {
        setEditWebAuthNDevice(device);
    };

    // const handleTotpDelete = (device: any): void => {
    //     console.log(device);
    // };

    const handleWebauthNDelete = (device: any): void => {
        setDeleteWebAuthNDevice(device);
    };

    // const handleTotpDeleteDeviceSubmit = (): void => {
    //     console.log('delete!');
    // };

    const handleDeleteWebAuthNSubmit: SubmitFunction<typeof deleteWebAuthNDeviceShape> = (values): void => {
        if (deleteWebAuthNDevice) {
            if (values && values!.password) {
                callDeleteWebAuthNDevice(deleteWebAuthNDevice, values.password as unknown as string)
                    .then(() => {
                        setAlert({show: true, message: 'security.webauthn.deletion.success', severity: 'success'});
                    })
                    .catch((reason) => {
                        console.error('Could not delete WebAuthN device: ', reason);
                        setAlert({show: true, message: 'security.error.webauthn.delete', severity: 'error'});
                    })
                    .finally(() => {
                        setDeleteWebAuthNDevice(undefined);
                        setLoading(true);
                    });
            }
        }
    };

    const handleEditWebAuthNDeviceSubmit: SubmitFunction<typeof editWebAuthNDeviceShape> = (values): void => {
        if (editWebAuthNDevice) {
            callWithJwt('/webauthn-devices/' + String(editWebAuthNDevice.id), 'PATCH', {name: values!.label})
                .then(() => {
                    setEditWebAuthNDevice(undefined);
                    setLoading(true);
                })
                .catch((reason) => {
                    console.error('Could not edit WebAuthN Device: ', reason);
                    setAlert({show: true, message: 'security.error.webauthn.edit', severity: 'error'});
                })
                .finally(() => {
                    setEditWebAuthNDevice(undefined);
                    setLoading(true);
                    setAlert({show: false, message: '', severity: 'info'});
                });
        }
    };


    if (loading) {
        return <CenteredCircularProgress/>;
    }

    return <>
        {alert.show &&
            <Card>
                <CardContentWithoutPadding>
                    <Grid item xs={12}>
                        <Alert severity={alert.severity as AlertColor}>
                            <Trans t={t} ns="account">{alert.message}</Trans>
                        </Alert>
                    </Grid>
                </CardContentWithoutPadding>
            </Card>}
        {/*<Card>
            <CardHeader title={t('security.totp.header')}/>
            <CardContentWithoutPadding>
                <Grid container>
                    <MarginGrid item xs={12}>
                        <Typography>
                            {t('security.totp.info')}
                        </Typography>
                    </MarginGrid>
                    {totpDevices!.length === 0
                        ? <></>
                        : <MarginGrid container spacing={2}>
                            {totpDevices!.map((device, index) => <Grid item xs={12} key={index}>
                                <Card>
                                    <TextWrappableCardHeader title={device.name}
                                                subheader={t('security.created', {when: new Date(device.created).toDateString()})}
                                                action={
                                                    <ActionAreaContainer>
                                                        <ActionButtonContainer>
                                                            <IconButtonWithTooltip onClick={() => handleTotpEdit(device)}
                                                                                   label={t('security.totp.edit')}>
                                                                <EditOutlined/>
                                                            </IconButtonWithTooltip>
                                                            <IconButtonWithTooltip onClick={() => handleTotpDelete(device)}
                                                                                   label={t('security.totp.delete')}>
                                                                <DeleteOutlined/>
                                                            </IconButtonWithTooltip>
                                                        </ActionButtonContainer>
                                                        <Typography variant="body2">
                                                            {
                                                                device.enabled
                                                                    ? t('security.device.enabled')
                                                                    : t('security.device.disabled')
                                                            }
                                                        </Typography>
                                                    </ActionAreaContainer>}/>
                                </Card>
                            </Grid>)}
                        </MarginGrid>}
                    <MarginGrid item xs={12}>
                        <Button onClick={() => setCreateTotpModal(true)} variant="contained" fullWidth>{t('security.totp.add')}</Button>
                    </MarginGrid>
                </Grid>
            </CardContentWithoutPadding>
        </Card>*/}
        <Card>
            <CardHeader title={t('security.webauthn.header')}/>
            <CardContentWithoutPadding>
                <Grid container>
                    <MarginGrid item xs={12}>
                        <Typography>
                            {t('security.webauthn.info')}
                        </Typography>
                    </MarginGrid>
                    {webauthnDevices.length === 0
                        ? <></>
                        : <MarginGrid container spacing={2}>
                            {webauthnDevices!.map((device, index) => <Grid item xs={12} key={index}>
                                <Card>
                                    <TextWrappableCardHeader title={device.name}
                                                             subheader={t('security.created', {when: new Date(device.created).toLocaleDateString(getCurrentLocale())})}
                                                             action={<ActionAreaContainer>
                                                                 <ActionButtonContainer>
                                                                     <IconButtonWithTooltip onClick={() => handleWebAuthNEdit(device)}
                                                                                            label={t('security.webauthn.edit')}>
                                                                         <EditOutlined/>
                                                                     </IconButtonWithTooltip>
                                                                     <IconButtonWithTooltip onClick={() => handleWebauthNDelete(device)}
                                                                                            label={t('security.webauthn.delete')}>
                                                                         <DeleteOutlined/>
                                                                     </IconButtonWithTooltip>
                                                                 </ActionButtonContainer>
                                                                 <Typography variant="body2">
                                                                     {
                                                                         device.enabled
                                                                             ? t('security.device.enabled')
                                                                             : t('security.device.disabled')
                                                                     }
                                                                 </Typography>
                                                             </ActionAreaContainer>}/>
                                </Card>
                            </Grid>)}
                        </MarginGrid>
                    }
                    {supportsWebAuthN
                        ? <MarginGrid item xs={12}>
                            <Button onClick={() => setCreateWebAuthNModal(true)}
                                    variant="contained"
                                    fullWidth>{t('security.webauthn.add')}</Button>
                        </MarginGrid>
                        : <MarginGrid item xs={12}>
                            <Alert severity="error" variant="outlined">
                                {t('security.webauthn.support')}
                            </Alert>
                        </MarginGrid>}
                </Grid>
            </CardContentWithoutPadding>
        </Card>
        {/*<TotpModals t={t}
                    createTotpModal={createTotpModal}
                    setCreateTotpModal={setCreateTotpModal}
                    createTotpDeviceShape={createTotpDeviceShape}
                    handleCreateTotpDeviceSubmit={handleCreateTotpDeviceSubmit}
                    deleteTotpDevice={deleteTotpDevice}
                    handleDeleteTotpModalClose={handleDeleteTotpModalClose}
                    deleteTotpDeviceShape={deleteTotpDeviceShape}
                    handleTotpDeleteDeviceSubmit={handleTotpDeleteDeviceSubmit}
                    editTotpDevice={editTotpDevice}
                    handleEditTotpDeviceSubmit={handleEditTotpDeviceSubmit}
                    editTotpDeviceShape={editTotpDeviceShape}
                    handleEditTotpDeviceClose={handleEditTotpModalClose}/>*/}
        <WebAuthNModals t={t}
                        createWebAuthNModal={createWebAuthNModal}
                        createWebAuthNDeviceShape={createWebAuthNDeviceShape}
                        setCreateWebAuthNModal={setCreateWebAuthNModal}
                        handleCreateWebAuthNDeviceSubmit={handleCreateWebAuthNDeviceSubmit}
                        deleteWebAuthNDevice={deleteWebAuthNDevice}
                        deleteWebAuthNDeviceShape={deleteWebAuthNDeviceShape}
                        deleteLastDevice={deleteLastDevice}
                        handleDeleteWebAuthNDeviceClose={handleDeleteWebAuthNModalClose}
                        handleDeleteWebAuthNDeviceSubmit={handleDeleteWebAuthNSubmit}
                        editWebAuthNDevice={editWebAuthNDevice}
                        handleEditWebAuthNDeviceSubmit={handleEditWebAuthNDeviceSubmit}
                        editWebAuthNDeviceShape={editWebAuthNDeviceShape}
                        handleEditWebAuthNDeviceClose={handleEditWebAuthNModalClose}/>
    </>;
};

const CardContentWithoutPadding = styled(CardContent)`
    &:last-child {
        padding-bottom: 16px;
    }
`;

const AccountSecurityOverviewWithHOCs = withLoginRequirement(AccountSecurityOverview);

export default AccountSecurityOverviewWithHOCs;
