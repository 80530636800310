import styled from 'styled-components';
import {CardHeader} from '@mui/material';

const LessPaddedCardHeader = styled(CardHeader)`
    padding: 0.25rem;

    & span {
        font-size: 1.1rem;
    }
`;

export default LessPaddedCardHeader;
