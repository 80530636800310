import React from 'react';
import {Card, CardContent, CardHeader, Grid, Table, TableBody, TableCell, TableRow} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import {AddRounded, EditRounded} from '@mui/icons-material';
import {IconButtonWithTooltip, LowercaseButton} from '@common/butterfly-shared-react-library';
import withLoginRequirement from '@/Components/withLoginRequirement';
import {callWithJwt} from '@/Helpers/jwtHelpers';
import type {BankAccount} from '@/Areas/Payment/Data/BankAccount';
import CenteredCircularProgress from '@/Components/CenteredCircularProgress';
import TableRowHeading from '@/Components/Tables/TableRowHeading';
import ConfirmPaymentMethodDeletion from '@/Areas/Payment/Components/ConfirmPaymentMethodDeletion';
import LessPaddedCardHeader from '@/Components/Layout/LessPaddedCardHeader';
import {fetchBankAccounts} from '@/Areas/Payment/Helpers/BankAccountHelpers';

const BankAccountOverview = (): React.JSX.Element => {
    const [bankAccounts, setBankAccounts] = React.useState<BankAccount[]>();
    const [accountToDelete, setAccountToDelete] = React.useState<BankAccount>();
    const [deletionError, setDeletionError] = React.useState<string>();
    const [error, setError] = React.useState(false);

    const {t} = useTranslation(['common', 'payment', 'error']);

    React.useEffect(() => {
        void fetchBankAccounts(setBankAccounts, setError);
    }, []);

    const handleOpenDeleteConfirm = (deleteWhich: BankAccount): void => {
        setAccountToDelete(deleteWhich);
    };

    const handleCloseDeleteConfirm = (): void => {
        setAccountToDelete(undefined);
        setDeletionError(undefined);
    };

    const handleAccountDeletion = (): void => {
        callWithJwt('/accounts/' + String(accountToDelete!.id), 'DELETE')
            .then(() => {
                setBankAccounts((oldBankAccounts) => {
                    return oldBankAccounts!.filter((bankAccount: BankAccount) => bankAccount.id !== accountToDelete!.id);
                });
                handleCloseDeleteConfirm();
            })
            .catch((reason) => {
                console.error('Failed to delete bank account', reason);
                setDeletionError('deletion_failed');
            });
    };

    if (error) {
        return <Card>
            <CardHeader title={t('error:heading')}/>
            <CardContent>
                <p>{t('payment:account.fetch_failed')}</p>
            </CardContent>
        </Card>;
    }

    if (!bankAccounts) {
        return <CenteredCircularProgress/>;
    }

    return <>
        <Card>
            <CardHeader title={t('payment:account.heading')}
                        action={<IconButtonWithTooltip label={t('payment:account.add')} component={Link}
                                                       to={'/account/payment/bank/add'}>
                            <AddRounded/>
                        </IconButtonWithTooltip>}/>
            <CardContent>
                {bankAccounts!.length === 0
                    ? <p>{t('payment:account.none_found')}</p>
                    : <Grid container spacing={2}>
                        {bankAccounts!.map((bankAccount, index) => <Grid item key={index}>
                            <Card>
                                <LessPaddedCardHeader
                                    title={bankAccount.name === '' ? t('payment:bank_account') : bankAccount.name}
                                    action={<IconButtonWithTooltip label={t('payment:account.edit')}
                                                                   component={Link}
                                                                   to={'/account/payment/bank/' + String(bankAccount.id) + '/edit'}>
                                        <EditRounded/>
                                    </IconButtonWithTooltip>}/>
                                <CardContent>
                                    <Table size="small">
                                        <TableBody>
                                            <TableRow>
                                                <TableRowHeading>{t('common:iban')}:</TableRowHeading>
                                                <TableCell>{bankAccount.identifier}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableRowHeading>{t('payment:bic')}:</TableRowHeading>
                                                <TableCell>{bankAccount.bic}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableRowHeading>{t('common:internal_comment')}:</TableRowHeading>
                                                <TableCell>{bankAccount.internal_comment}</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                    <br/>
                                    <LowercaseButton color="error"
                                                     variant="contained"
                                                     fullWidth
                                                     onClick={() => handleOpenDeleteConfirm(bankAccount)}>
                                        {t('payment:account.delete')}
                                    </LowercaseButton>
                                </CardContent>
                            </Card>
                        </Grid>)}
                    </Grid>}
            </CardContent>
        </Card>
        <ConfirmPaymentMethodDeletion t={t}
                                      which="account"
                                      error={deletionError}
                                      onClose={handleCloseDeleteConfirm}
                                      onDelete={handleAccountDeletion}
                                      toDelete={accountToDelete}/>
    </>;
};


export default withLoginRequirement(BankAccountOverview);
