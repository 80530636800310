import styled from 'styled-components';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    CardContent,
    CardHeader,
    Chip,
    ChipTypeMap,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    Tooltip,
} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {ExpandMore} from '@mui/icons-material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import React from 'react';
import {formatNumberWithLargeSiPrefix} from '@/Helpers/numberFormatHelpers';
import {ChargeConnectorStatus, SimplifiedConnectorWithLocationInfo} from '@/Types/ChargeTypes';
import {StyledCard, StyledTableRow} from '@/Components/Layout/Common';

const StatusDiv = styled.div`
    margin-top: 1rem;
    margin-bottom: 1rem;
`;

const statusToThemeMap: Record<ChargeConnectorStatus, ChipTypeMap['props']['color']> = {
    [ChargeConnectorStatus.AVAILABLE]: 'success',
    [ChargeConnectorStatus.BLOCKED]: 'error',
    [ChargeConnectorStatus.CHARGING]: 'info',
    [ChargeConnectorStatus.INOPERATIVE]: 'error',
    [ChargeConnectorStatus.OUTOFORDER]: 'error',
    [ChargeConnectorStatus.PLANNED]: 'warning',
    [ChargeConnectorStatus.PREPARING]: 'info',
    [ChargeConnectorStatus.RESERVED]: 'warning',
    [ChargeConnectorStatus.REMOVED]: 'error',
    [ChargeConnectorStatus.UNKNOWN]: 'error',
};

const ConnectorInfo = (props: {connector: SimplifiedConnectorWithLocationInfo}): React.JSX.Element => {
    const {connector} = props;
    const {t} = useTranslation(['connector', 'chargepoint']);

    const formattedPower = formatNumberWithLargeSiPrefix(connector.power, 1, 'W');

    return (
        <StyledCard>
            <CardHeader title={`EVSE-ID: ${connector.uid}`}/>
            <CardContent>
                <StatusDiv>
                    {t('connector:status')}{' '}
                    <Chip
                        label={t(`chargepoint:connector_status.${connector.status}`)}
                        variant="outlined"
                        color={statusToThemeMap[connector.status]}
                    />
                    {connector.status === ChargeConnectorStatus.CHARGING && (
                        <Tooltip
                            title={t('chargepoint:charging_explainer', {
                                charging: t('chargepoint:connector_status.CHARGING'),
                            })}
                            arrow
                            placement="top"
                            enterTouchDelay={10}
                            leaveTouchDelay={10000}
                        >
                            <HelpOutlineIcon/>
                        </Tooltip>
                    )}
                </StatusDiv>
                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMore/>}>
                        {t('connector:connector_data', {
                            format: t(`chargepoint:plug.format.${connector.format}`),
                            standard: t(`chargepoint:plug.${connector.standard}`),
                            power: formattedPower,
                            interpolation: {skipOnVariables: false},
                        })}
                    </AccordionSummary>
                    <AccordionDetails>
                        <TableContainer component={Paper}>
                            <Table size={'small'}>
                                <TableBody>
                                    <StyledTableRow>
                                        <TableCell align="right">
                                            <strong>{t('chargepoint:plug.format.label')}</strong>
                                        </TableCell>
                                        <TableCell>{t(`chargepoint:plug.format.${connector.format}`)}</TableCell>
                                    </StyledTableRow>
                                    <StyledTableRow>
                                        <TableCell align="right">
                                            <strong>{t('chargepoint:power')}</strong>
                                        </TableCell>
                                        <TableCell>{formattedPower}</TableCell>
                                    </StyledTableRow>
                                    <StyledTableRow>
                                        <TableCell align="right">
                                            <strong>{t('chargepoint:plug.label')}</strong>
                                        </TableCell>
                                        <TableCell>{t(`chargepoint:plug.${connector.standard}`)}</TableCell>
                                    </StyledTableRow>
                                    <StyledTableRow>
                                        <TableCell align="right">
                                            <strong>{t('chargepoint:power_type.label')}</strong>
                                        </TableCell>
                                        <TableCell>{t(`chargepoint:power_type.${connector.power_type}`)}</TableCell>
                                    </StyledTableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </AccordionDetails>
                </Accordion>
                <p>{t('connector:address', {connector})}</p>
            </CardContent>
        </StyledCard>
    );
};

export default ConnectorInfo;
