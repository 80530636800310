import styled from 'styled-components';
import {Avatar} from '@mui/material';

const UserAvatar = styled(Avatar)`
    width: 1.8rem;
    height: 1.8rem;
    font-size: medium;
    margin-right: 0.8rem;
    background-color: ${props => props.theme.palette.primary.main};
`;

export default UserAvatar;
