import React from 'react';
import {useTranslation} from 'react-i18next';
import {LanguageRounded} from '@mui/icons-material';
import {ListItemText, Menu, MenuItem, MenuList} from '@mui/material';
import {setItem} from '@/Helpers/localStorageHelpers';
import {NavbarPopupMenu} from '@/Components/Layout/Common';
import {LeftMenuItem, PrimaryColorListItemIcon} from '@/Components/MenuComponents';

const LanguageSelect = (): React.JSX.Element => {
    const [anchorEl, setAnchorEl] = React.useState<Element | null>(null);

    const {t, i18n} = useTranslation('common');
    const options = {
        de: t('language_selection.german'),
        en: t('language_selection.english'),
        nl: t('language_selection.dutch'),
        fr: t('language_selection.french'),
    };

    const handleLanguageSelectorClick = (event: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement | HTMLLIElement, MouseEvent>): void => {
        if (event.currentTarget !== undefined) {
            setAnchorEl(event.currentTarget);
        }
    };

    const handleLanguageSelectClick = (event: React.MouseEvent<HTMLLIElement, MouseEvent>, index: string): void => {
        void i18n.changeLanguage(index).then(() => {
            setItem('language', index);
            setAnchorEl(null);
        });
    };

    const handleClose = (): void => {
        setAnchorEl(null);
    };

    return (
        <>
            <LeftMenuItem onClick={handleLanguageSelectorClick}>
                <PrimaryColorListItemIcon><LanguageRounded/></PrimaryColorListItemIcon>
                <ListItemText>{t(('common:language_short.' + i18n.language) as any)}</ListItemText>
            </LeftMenuItem>
            <NavbarPopupMenu>
                <Menu
                    id="language-menu"
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    transitionDuration={0}
                    slotProps={{paper: {style: {minWidth: 'min-content', width: '158px'}}}}
                >
                    <MenuList>
                        {(Object.keys(options) as Array<keyof typeof options>).map(key => (
                            <MenuItem
                                key={key}
                                selected={key === i18n.language}
                                onClick={event => handleLanguageSelectClick(event, key)}
                            >
                                {options[key]}
                            </MenuItem>
                        ))}
                    </MenuList>
                </Menu>
            </NavbarPopupMenu>
        </>
    );
};

export default LanguageSelect;
