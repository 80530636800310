import React from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {LoggedInState, useUserContext} from '@/Contexts/User/UserContext';
import CenteredCircularProgress from '@/Components/CenteredCircularProgress';

function withLoginRequirement<T extends React.JSX.IntrinsicAttributes>(WrappedComponent: React.ComponentType<T>) {
    return function LoginRequirementInner(props: T) {
        const navigate = useNavigate();
        const location = useLocation();

        const {loggedInState} = useUserContext();

        React.useEffect(() => {
            if (loggedInState === LoggedInState.LoggedOut) {
                navigate('/login', {state: {redirected_from: location.pathname}});
            }
        }, [loggedInState]);

        if (loggedInState === LoggedInState.NotYetKnown) {
            return <CenteredCircularProgress data-testid="logged-in-not-known"/>;
        }

        if (loggedInState === LoggedInState.LoggedOut) {
            return <CenteredCircularProgress data-testid="logged-in-waiting-for-redirect"/>;
        }

        return <WrappedComponent {...(props as T)} />;
    };
}

export default withLoginRequirement;
